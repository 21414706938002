<template>
  <div class="profile">
    <MemberProfileHeader
      class="profile-header"
      :loading="memberInfoLoading"
      :member-info="memberInfo"
      :info-editable="isPersonalPage"
      @back="onBackClick"
    />
    <div ref="profilemain" class="profile-main" :style="maxHeightStyle">
      <MemberProfileInsights
        v-if="!memberInsightsLoading"
        class="profile-insights"
        :member-info="memberInfo"
        :member-insights="memberInsights"
        :clickable="canUseSearch"
        @pill-click="handlePillClick"
      />
      <MemberProfileContent
        v-if="!memberContentLoading && memberContent.length > 0"
        class="profile-content"
        :resources="memberContent"
        :search-id="memberContentSearchId"
        @browseAllClicked="handleBrowseAllClicked"
        @gotoResource="gotoResource"
      />
      <MemberProfileEmpty v-if="memberContentEmpty" />
      <div
        v-if="memberContentLoading || memberInsightsLoading"
        class="profile-loading"
      >
        <b-loading active :is-full-page="false"></b-loading>
      </div>
    </div>
    <ResourceModal />
  </div>
</template>

<script>
import { getLink } from '@c/models/Resource'
import { ORDERING_MAP, SORT_TYPES_ENUM } from '@c/models/SortingFiltering'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import MemberProfileContent from './profile/MemberProfileContent.vue'
import MemberProfileEmpty from './profile/MemberProfileEmpty.vue'
import MemberProfileHeader from './profile/MemberProfileHeader.vue'
import MemberProfileInsights from './profile/MemberProfileInsights.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'MemberProfile',
  components: {
    MemberProfileHeader,
    MemberProfileInsights,
    MemberProfileContent,
    ResourceModal,
    MemberProfileEmpty
  },
  mixins: [MaxHeightMixin],
  data() {
    return {
      memberInfo: {},
      memberInfoLoading: true,
      memberInsights: {},
      memberInsightsLoading: true,
      memberContent: [],
      memberContentSearchId: '',
      memberContentLoading: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspaceMember', 'showPeopleTab', 'canUseSearch']),
    isPersonalPage() {
      return (
        this.$route.params.member_id === 'me' ||
        this.$route.params.member_id === this.currentWorkspaceMember.uuid
      )
    },
    showSidebar() {
      return !isEmpty(this.memberInfo) && !isEmpty(this.memberInsights)
    },
    memberInfoInUrl() {
      return this.$route.params
    },
    memberContentEmpty() {
      return (
        !this.memberInsightsLoading &&
        !this.memberContentLoading &&
        Object.keys(this.memberInsights).every((key) =>
          isEmpty(this.memberInsights[key])
        ) &&
        isEmpty(this.memberContent)
      )
    }
  },
  watch: {
    memberInfoInUrl: {
      handler() {
        this.memberInfoLoading = true
        this.memberInsightsLoading = true
        this.memberContentLoading = true
        this.getProfilePageData()
      },
      deep: true
    },
    currentWorkspaceMember: {
      async handler() {
        if (this.isPersonalPage) {
          this.memberInfoLoading = true
          const memberInfo = await this.getMemberInfo()
          this.memberInfo = memberInfo || {}
          this.memberInfoLoading = false
        }
      },
      deep: true
    }
  },
  created() {
    this.getProfilePageData()
  },
  methods: {
    ...mapActions([
      'getWorkspaceMember',
      'getWorkspaceMemberInsights',
      'getSearchResourcesPage',
      'popBreadCrumb'
    ]),
    getMaxHeightElement() {
      return this.$refs.profilemain
    },
    async getProfilePageData() {
      try {
        const memberInfo = await this.getMemberInfo()
        this.memberInfo = memberInfo || {}
        this.memberInfoLoading = false

        const memberInsights = await this.getMemberInsights()
        this.memberInsights = memberInsights || {}
        this.memberInsightsLoading = false

        const latestCreated = await this.getMemberLatestCreated(memberInfo)
        this.memberContent = latestCreated?.response_data?.resources || []
        this.memberContentSearchId =
          latestCreated?.response_data?.search_id || []
        this.memberContentLoading = false
      } catch (e) {
        this.$console.error('Something went wrong while getting member info', e)
        this.$toast.error(e, 'getting member info')
        return
      }
    },
    async getMemberInfo() {
      return this.getWorkspaceMember({
        workspace_id: this.$route.params.workspace_id,
        member_id: this.isPersonalPage
          ? this.currentWorkspaceMember.uuid
          : this.$route.params.member_id,
        upsert: false
      })
    },
    async getMemberInsights() {
      return this.getWorkspaceMemberInsights({
        workspace_id: this.$route.params.workspace_id,
        member_id: this.isPersonalPage
          ? this.currentWorkspaceMember.uuid
          : this.$route.params.member_id
      })
    },
    async getMemberLatestCreated(member) {
      return await this.getSearchResourcesPage({
        data: {
          sort_by: ORDERING_MAP[SORT_TYPES_ENUM.MODIFIED_DATE],
          with_search_highlights: false,
          extract_entities: false,
          authors: [
            member.user?.username ||
              member.integrationusers.find((iu) => iu.email)?.email
          ]
        },
        extraParams: {
          page_size: 9,
          included_facets: 'authors types',
          workspace: this.$route.params.workspace_id,
          ...(this.$route.query.act_as
            ? { act_as: this.$route.query.act_as }
            : {})
        },
        resourceType: 'all'
      })
    },
    onBackClick() {
      this.$router.go(-1)
    },
    handlePillClick(pill) {
      const { type, target } = pill
      this.$router.push({
        name: 'search',
        params: {
          workspace_id: this.$route.params.workspace_id
        },
        query: {
          [type]: target.name,
          authors:
            this.memberInfo?.user?.username ||
            this.memberInfo.integrationusers?.find((iu) => iu.email)?.email
        }
      })
    },
    handleBrowseAllClicked() {
      this.$router.push({
        name: 'search',
        params: {
          workspace_id: this.$route.params.workspace_id
        },
        query: {
          authors:
            this.memberInfo?.user?.username ||
            this.memberInfo.integrationusers?.find((iu) => iu.email)?.email
        }
      })
    },
    gotoResource(resource) {
      const link = getLink(resource)
      this.$console.log('Going to', link)
      this.$resource.goToResource(link)
    }
  }
}
</script>

<style scoped lang="scss">
.profile {
  display: flex;
  flex-flow: column nowrap;

  &-back {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #60666b;
    cursor: pointer;

    &-chevron {
      height: 0.5rem;
      transform: rotate(90deg);
      user-select: none;
    }
  }

  &-main {
    padding: 2rem 2.5rem;
    overflow-y: auto;
  }

  &-loading {
    position: relative;
    height: 20vh;
  }
}
</style>
