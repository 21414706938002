<template>
  <div class="profile-empty">
    <img src="@/assets/icons/user.svg" alt="" class="profile-empty-icon" />
    <p class="profile-empty-header">
      No information is available for this user right now.
    </p>
    <p class="profile-empty-subheader">
      Once they create some content, you will be able to see it here.
    </p>
  </div>
</template>

<script>
export default {
  name: 'MemberProfileEmpty'
}
</script>

<style scoped lang="scss">
.profile-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-icon {
    width: 4rem;
    padding: 0.5rem;
    background: #e9ebed;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    aspect-ratio: 1;
    object-fit: contain;
  }

  &-header {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  &-subheader {
    color: #60666b;
    margin-bottom: 0.5rem;
  }
}
</style>
