var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-top" },
      [
        _c("Button", {
          attrs: {
            text: "Back",
            icon: "arrow-left",
            "icon-left": true,
            type: "grey"
          },
          on: {
            click: function() {
              return _vm.$emit("back")
            }
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "header-body" }, [
      _c(
        "div",
        { staticClass: "header-body-content" },
        [
          _c("Avatar", {
            attrs: {
              user: _vm.userObject || {},
              "fallback-icon": "user",
              size: "l"
            }
          }),
          _c("div", { staticClass: "header-body-content-info" }, [
            _c("p", { staticClass: "header-body-content-info-name" }, [
              _vm._v(_vm._s(_vm.userFullName))
            ]),
            _vm.role
              ? _c("p", { staticClass: "header-body-content-info-role" }, [
                  _vm._v(_vm._s(_vm.role))
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "header-body-btns" },
        [
          _vm.infoEditable
            ? _c("Button", {
                attrs: { text: "Edit info" },
                on: { click: _vm.handleEditClicked }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }