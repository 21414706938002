var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c("MemberProfileHeader", {
        staticClass: "profile-header",
        attrs: {
          loading: _vm.memberInfoLoading,
          "member-info": _vm.memberInfo,
          "info-editable": _vm.isPersonalPage
        },
        on: { back: _vm.onBackClick }
      }),
      _c(
        "div",
        {
          ref: "profilemain",
          staticClass: "profile-main",
          style: _vm.maxHeightStyle
        },
        [
          !_vm.memberInsightsLoading
            ? _c("MemberProfileInsights", {
                staticClass: "profile-insights",
                attrs: {
                  "member-info": _vm.memberInfo,
                  "member-insights": _vm.memberInsights,
                  clickable: _vm.canUseSearch
                },
                on: { "pill-click": _vm.handlePillClick }
              })
            : _vm._e(),
          !_vm.memberContentLoading && _vm.memberContent.length > 0
            ? _c("MemberProfileContent", {
                staticClass: "profile-content",
                attrs: {
                  resources: _vm.memberContent,
                  "search-id": _vm.memberContentSearchId
                },
                on: {
                  browseAllClicked: _vm.handleBrowseAllClicked,
                  gotoResource: _vm.gotoResource
                }
              })
            : _vm._e(),
          _vm.memberContentEmpty ? _c("MemberProfileEmpty") : _vm._e(),
          _vm.memberContentLoading || _vm.memberInsightsLoading
            ? _c(
                "div",
                { staticClass: "profile-loading" },
                [
                  _c("b-loading", {
                    attrs: { active: "", "is-full-page": false }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("ResourceModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }