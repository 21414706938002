<template>
  <div class="profile-content">
    <span class="profile-content-header">Worked on</span>
    <div v-show="allImagesLoaded" class="profile-content-list">
      <ResourceCard
        v-for="(resource, idx) in resources"
        :key="resource.uuid"
        :resource="resource"
        :rank="idx + 1"
        location="user profile"
        :search-id="searchId"
        @load="imagesLoaded += 1"
        @cantLoad="imagesErrored += 1"
        @gotoResource="$emit('gotoResource', resource)"
      />
    </div>
    <p
      v-show="allImagesLoaded"
      class="profile-content-btn"
      @click="$emit('browseAllClicked')"
    >
      <img
        src="@/assets/icons/search-medium.svg"
        alt=""
        class="profile-content-btn-icon"
      />
      Browse all work
    </p>
  </div>
</template>

<script>
import ResourceCard from '@c/shared/molecules/object-visualisations/resource/ResourceCard.vue'

export default {
  name: 'MemberProfileContent',
  components: {
    ResourceCard
  },
  props: {
    resources: {
      type: Array,
      required: true
    },
    searchId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    imagesLoaded: 0,
    imagesErrored: 0
  }),
  computed: {
    allImagesLoaded() {
      return this.imagesLoaded + this.imagesErrored === this.resources.length
    }
  }
}
</script>

<style lang="scss">
.profile-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;

  &-header {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  &-btn {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: $primary;
    color: white;
    border-radius: 999rem;
    width: fit-content;
    align-self: center;
    user-select: none;
    cursor: pointer;

    &-icon {
      height: 1.2rem;
      filter: brightness(0) invert(1);
    }
  }
}
</style>
