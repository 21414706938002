var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-empty" }, [
      _c("img", {
        staticClass: "profile-empty-icon",
        attrs: { src: require("@/assets/icons/user.svg"), alt: "" }
      }),
      _c("p", { staticClass: "profile-empty-header" }, [
        _vm._v(" No information is available for this user right now. ")
      ]),
      _c("p", { staticClass: "profile-empty-subheader" }, [
        _vm._v(
          " Once they create some content, you will be able to see it here. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }