<template>
  <div class="insights">
    <div v-if="topics.length > 0" class="insights-block">
      <span class="insights-block-title">Expertise</span>
      <div class="insights-block-pills">
        <div
          v-for="topic in topicsCropped"
          :key="topic.name"
          class="insights-block-pills-pill"
          :class="{ clickable }"
          @click="() => handleClick('topics', topic)"
        >
          <span class="insights-block-pills-pill-name">
            {{ topic.name }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="organisations.length > 0" class="insights-block">
      <span class="insights-block-title">Customers</span>
      <div class="insights-block-pills">
        <div
          v-for="organisation in organisationsCropped"
          :key="organisation.name"
          class="insights-block-pills-pill"
          :class="{ clickable }"
          @click="() => handleClick('organisations', organisations)"
        >
          <img
            v-if="organisation.logo"
            :src="organisation.logo"
            alt=""
            class="insights-block-pills-pill-logo"
          />
          <span
            v-else
            class="insights-block-pills-pill-logo"
            :style="{ background: $umodel.user_color(organisation) }"
            >{{ $umodel.initials(organisation.name) }}</span
          >
          <span class="insights-block-pills-pill-name">{{
            organisation.name
          }}</span>
        </div>
      </div>
    </div>
    <div v-if="industries.length > 0" class="insights-block">
      <span class="insights-block-title">Industries</span>
      <div class="insights-block-pills">
        <div
          v-for="industry in industriesCropped"
          :key="industry.name"
          class="insights-block-pills-pill"
          :class="{ clickable }"
          @click="() => handleClick('industries', industry)"
        >
          <span class="insights-block-pills-pill-name">
            {{ industry.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberProfileInsights',
  props: {
    memberInfo: {
      type: Object,
      required: true
    },
    memberInsights: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showMore: {
      topics: false,
      organisations: false,
      industries: false,
      users: false
    }
  }),
  computed: {
    topics() {
      return this.memberInsights.topics
    },
    topicsCropped() {
      return this.showMore.topics ? this.topics : this.topics.slice(0, 5)
    },
    organisations() {
      return this.memberInsights.organisations
    },
    organisationsCropped() {
      return this.showMore.organisations
        ? this.organisations
        : this.organisations.slice(0, 10)
    },
    industries() {
      return this.memberInsights.industries
    },
    industriesCropped() {
      return this.showMore.industries
        ? this.industries
        : this.industries.slice(0, 5)
    }
  },
  methods: {
    handleClick(type, target) {
      if (!this.clickable) return
      this.$emit('pill-click', { type, target })
    }
  }
}
</script>

<style scoped lang="scss">
.insights {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  &-block {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);
    height: 100%;
    padding: 1.5rem;

    &-title {
      font-size: 1.2rem;
      font-weight: 700;
      color: #303032;
    }

    &-pills {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;

      &-pill {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.3rem 0.75rem;
        border-radius: 6px;
        background: #f1f2f3;
        overflow: hidden;

        &.clickable {
          cursor: pointer;

          &:hover {
            background: #e6e7e8;
          }
        }

        &-name {
          color: #8f9399;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-logo {
          display: inline-flex;
          min-height: 1.5rem;
          max-height: 1.5rem;
          min-width: 1.5rem;
          max-width: 1.5rem;

          align-items: center;
          justify-content: center;

          border-radius: 999rem;
          font-size: 0.9rem;
          color: white;
          font-weight: 600;
        }
      }
    }

    &-cards {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;

      &-card {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        background-color: #f5f5f5;

        &-logo {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 1.5rem;
          max-width: 1.5rem;
          min-height: 1.5rem;
          max-height: 1.5rem;
          border-radius: 999rem;
          text-align: center;
          color: white;
          font-size: 0.75rem;
          font-weight: 600;
        }

        &-name {
          display: inline-block;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
