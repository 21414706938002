var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "insights" }, [
    _vm.topics.length > 0
      ? _c("div", { staticClass: "insights-block" }, [
          _c("span", { staticClass: "insights-block-title" }, [
            _vm._v("Expertise")
          ]),
          _c(
            "div",
            { staticClass: "insights-block-pills" },
            _vm._l(_vm.topicsCropped, function(topic) {
              return _c(
                "div",
                {
                  key: topic.name,
                  staticClass: "insights-block-pills-pill",
                  class: { clickable: _vm.clickable },
                  on: {
                    click: function() {
                      return _vm.handleClick("topics", topic)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "insights-block-pills-pill-name" },
                    [_vm._v(" " + _vm._s(topic.name) + " ")]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.organisations.length > 0
      ? _c("div", { staticClass: "insights-block" }, [
          _c("span", { staticClass: "insights-block-title" }, [
            _vm._v("Customers")
          ]),
          _c(
            "div",
            { staticClass: "insights-block-pills" },
            _vm._l(_vm.organisationsCropped, function(organisation) {
              return _c(
                "div",
                {
                  key: organisation.name,
                  staticClass: "insights-block-pills-pill",
                  class: { clickable: _vm.clickable },
                  on: {
                    click: function() {
                      return _vm.handleClick("organisations", _vm.organisations)
                    }
                  }
                },
                [
                  organisation.logo
                    ? _c("img", {
                        staticClass: "insights-block-pills-pill-logo",
                        attrs: { src: organisation.logo, alt: "" }
                      })
                    : _c(
                        "span",
                        {
                          staticClass: "insights-block-pills-pill-logo",
                          style: {
                            background: _vm.$umodel.user_color(organisation)
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$umodel.initials(organisation.name))
                          )
                        ]
                      ),
                  _c(
                    "span",
                    { staticClass: "insights-block-pills-pill-name" },
                    [_vm._v(_vm._s(organisation.name))]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.industries.length > 0
      ? _c("div", { staticClass: "insights-block" }, [
          _c("span", { staticClass: "insights-block-title" }, [
            _vm._v("Industries")
          ]),
          _c(
            "div",
            { staticClass: "insights-block-pills" },
            _vm._l(_vm.industriesCropped, function(industry) {
              return _c(
                "div",
                {
                  key: industry.name,
                  staticClass: "insights-block-pills-pill",
                  class: { clickable: _vm.clickable },
                  on: {
                    click: function() {
                      return _vm.handleClick("industries", industry)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "insights-block-pills-pill-name" },
                    [_vm._v(" " + _vm._s(industry.name) + " ")]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }