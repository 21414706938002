<template>
  <div class="header">
    <div class="header-top">
      <Button
        text="Back"
        icon="arrow-left"
        :icon-left="true"
        type="grey"
        @click="() => $emit('back')"
      />
    </div>
    <div class="header-body">
      <div class="header-body-content">
        <Avatar :user="userObject || {}" fallback-icon="user" size="l" />
        <div class="header-body-content-info">
          <p class="header-body-content-info-name">{{ userFullName }}</p>
          <!-- <p v-if="department" class="header-body-content-info-title">
            {{ department }}
          </p> -->
          <p v-if="role" class="header-body-content-info-role">{{ role }}</p>
        </div>
      </div>
      <div class="header-body-btns">
        <Button
          v-if="infoEditable"
          text="Edit info"
          @click="handleEditClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'MemberProfileHeader',
  components: {
    Avatar,
    Button
  },
  props: {
    memberInfo: {
      type: Object,
      required: true
    },
    infoEditable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userObject() {
      return (
        this.memberInfo?.user ||
        this.memberInfo?.integrationusers?.find(
          (iu) => iu.first_name && iu.last_name
        )
      )
    },
    userFullName() {
      return this.$umodel.full_name(this.userObject || {})
    },
    department() {
      return this.memberInfo?.department || ''
    },
    role() {
      return this.memberInfo?.role || ''
    }
  },
  watch: {
    loading(val) {
      if (!val) {
        this.checkMaxHeight()
      }
    }
  },
  methods: {
    handleEditClicked() {
      this.$modals.user.openProfileSettings(this, this.memberInfo?.user)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1.5rem 2.5rem;
  background: linear-gradient(
    94deg,
    rgba(#115efb, 0.06) -0.54%,
    rgba(#da42d4, 0.15) 89.06%
  );

  &-top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &-body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &-content {
      display: grid;
      grid-template-columns: auto 1fr;
      width: 100%;
      gap: 1.5rem;
      align-items: center;

      &-avatar {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 999rem;
        border: 1px solid transparent;
        border-radius: 999rem;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 1.75rem;

        &.avatar-small {
          width: 5rem;
          height: 5rem;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.25rem;
        width: min(max(50%, 500px), 100%);
        height: 100%;

        &-name {
          font-size: 2rem;
          font-weight: 700;

          &-wrapper {
            display: flex;
            gap: 0.5rem;
          }
        }

        &-title {
          border: 1px solid transparent;
          border-radius: 4px;
          color: #303032;
        }

        &-role {
          color: #60666b;
          font-size: 0.9rem;
        }
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      align-items: center;

      &-edit {
        padding: 0.5rem 1rem;
        background: $primary;
        border-radius: 999rem;
        color: white;
        font-weight: 600;
        white-space: nowrap;
        border: none;
        user-select: none;

        &:active {
          box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
        }
      }
    }
  }
}
</style>
