var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-content" }, [
    _c("span", { staticClass: "profile-content-header" }, [
      _vm._v("Worked on")
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.allImagesLoaded,
            expression: "allImagesLoaded"
          }
        ],
        staticClass: "profile-content-list"
      },
      _vm._l(_vm.resources, function(resource, idx) {
        return _c("ResourceCard", {
          key: resource.uuid,
          attrs: {
            resource: resource,
            rank: idx + 1,
            location: "user profile",
            "search-id": _vm.searchId
          },
          on: {
            load: function($event) {
              _vm.imagesLoaded += 1
            },
            cantLoad: function($event) {
              _vm.imagesErrored += 1
            },
            gotoResource: function($event) {
              return _vm.$emit("gotoResource", resource)
            }
          }
        })
      }),
      1
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.allImagesLoaded,
            expression: "allImagesLoaded"
          }
        ],
        staticClass: "profile-content-btn",
        on: {
          click: function($event) {
            return _vm.$emit("browseAllClicked")
          }
        }
      },
      [
        _c("img", {
          staticClass: "profile-content-btn-icon",
          attrs: { src: require("@/assets/icons/search-medium.svg"), alt: "" }
        }),
        _vm._v(" Browse all work ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }