import { render, staticRenderFns } from "./MemberProfileHeader.vue?vue&type=template&id=3885921b&scoped=true&"
import script from "./MemberProfileHeader.vue?vue&type=script&lang=js&"
export * from "./MemberProfileHeader.vue?vue&type=script&lang=js&"
import style0 from "./MemberProfileHeader.vue?vue&type=style&index=0&id=3885921b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3885921b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3885921b')) {
      api.createRecord('3885921b', component.options)
    } else {
      api.reload('3885921b', component.options)
    }
    module.hot.accept("./MemberProfileHeader.vue?vue&type=template&id=3885921b&scoped=true&", function () {
      api.rerender('3885921b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views-v2/profile/MemberProfileHeader.vue"
export default component.exports